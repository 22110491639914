<template>
  <div>
    <!-- Header Action-->
    <portal to="structure_header_action">
      <ws-button
          @click="displayPreview = true"
          label="Preview"
          outlined
          left-icon="mdi-eye"
          :color="wsACCENT"
          class="mr-2"
      />
      <ws-button
          @click="saveChanges"
          label="Save"
      />
    </portal>

    <!-- Content -->
    <v-sheet class="wsRoundedHalf mt-6">
      <div class="d-flex align-center my-5 ">
        <v-icon :color="wsACCENT">mdi-menu-{{ displayGeneralInfo ? 'down' : 'right' }}</v-icon>
        <h4 @click="displayGeneralInfo = !displayGeneralInfo" class="wsDARKER linkHover pointer">
          {{$t('GeneralInfo') }}
        </h4>
      </div>

      <v-expand-transition>
        <v-row v-if="displayGeneralInfo">
          <!-- Image-->
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsACCENT mb-2">
              {{ $t('CourseImage') }}
            </h5>
            <image-param-picker
                v-model="entityData.logo"
                no-border
            />
          </v-col>
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
              {{ $t('courses.description.image.tooltip') }}
            </h5>
          </v-col>

          <!-- Course Name -->
          <v-col cols="12" md="6">
            <ws-text-field
                :label="$t('wsu.education.education_program.name')"
                :placeholder="$t('wsu.education.education_program.work_name.placeholder')"
                v-model="entityData.work_name"
            />
          </v-col>
          <v-col v-if="!SM" cols="12" md="6"></v-col>

          <!-- Short Description-->
          <v-col cols="12" md="6">
            <ws-text-field
                v-model="entityData.short_description"
                :label="$t('ShortDescription')"
                :placeholder="$t('EnterShortDescription')"
                height="100"
                counter="200"
                area
                clearable
            />
          </v-col>
          <v-col cols="12" md="6"></v-col>


          <!-- Description -->
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsACCENT mb-2">
              {{ $t('courses.description.description.title') }}
            </h5>
            <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7">
              <ws-text-editor
                  v-model="entityData.description"
                  :html="entityData.description_text"
                  :value="[]"
                  is-public
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
              {{ $t('courses.description.description.tooltip') }}
            </h5>
          </v-col>

          <!-- Features-->
          <v-col cols="12" md="6">
            <course-description-features
                v-model="entityData.features"
            />
          </v-col>
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
              {{ $t('courses.description.features.tooltip') }}
            </h5>
          </v-col>

        </v-row>
      </v-expand-transition>


      <!-- Guarantor -->
      <div class="d-flex align-center my-5 ">
        <v-icon :color="wsACCENT">mdi-menu-{{ displayGuarantor ? 'down' : 'right' }}</v-icon>
        <h4 @click="displayGuarantor = !displayGuarantor" class="wsDARKER linkHover pointer">
          {{$t('wsu_structure.specialty.guarantor.title') }}
        </h4>
      </div>
      <v-expand-transition>
        <v-row  v-if="displayGuarantor">
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsACCENT mb-2">
              {{ $t('wsu_structure.specialty.guarantor.img') }}
            </h5>
            <image-param-picker
                v-model="entityData.guarantor.img"
                no-border
                class="mb-5"
            />

            <ws-text-field
                :label="$t('HumanName')"
                :placeholder="$t('wsu_structure.specialty.guarantor.name.label')"
                v-model="entityData.guarantor.name"
                class="mb-5"
            />
            <ws-text-field
                v-model="entityData.guarantor.short_description"
                :label="$t('wsu_structure.specialty.guarantor.short_description.label')"
                :placeholder="$t('wsu_structure.specialty.guarantor.short_description.placeholder')"
                height="100"
                counter="200"
                area
                clearable
            />

            <h5 class="font-weight-regular wsACCENT mb-2">
              {{ $t('wsu_structure.specialty.guarantor.description.label') }}
            </h5>
            <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7">

              <ws-text-editor
                  v-model="entityData.guarantor.description"
                  :html="entityData.description_text"
                  :value="[]"
                  is-public
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
              {{ $t('wsu_structure.specialty.guarantor.tooltip') }}
            </h5>
          </v-col>
        </v-row>
      </v-expand-transition>


      <v-row>

        <!-- Program  -->
        <v-col cols="12" md="6">

          <description-specialty-program
              v-model="entityData.program"
              @expand="displayCareer = $event"
              :specialty-id="entityData.uuid"
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayEnrollCommittee" class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.program.tooltip') }}
          </h5>
        </v-col>

        <!-- Career  -->
        <v-col cols="12" md="6">

          <description-specialty-career
              v-model="entityData.career"
              @expand="displayCareer = $event"
              :specialty-id="entityData.uuid"
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayEnrollCommittee" class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.career.tooltip') }}
          </h5>
        </v-col>

        <!-- Enrollment Committee  -->
        <v-col cols="12" md="6">

          <description-specialty-enrollment-committee
              v-model="entityData.enroll_committee"
              @expand="displayEnrollCommittee = $event"
              :specialty-id="entityData.uuid"
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayEnrollCommittee" class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.enroll_committee.tooltip') }}
          </h5>
        </v-col>

        <!-- Alumni  -->
        <v-col cols="12" md="6">
          <description-specialty-alumni
              v-model="entityData.alumni"
              @expand="displayAlumnis = $event"
              :specialty-id="entityData.uuid"
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayAlumnis" class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.alumnis.tooltip') }}
          </h5>
        </v-col>

      </v-row>

      <!-- FAQ && Enrollemnt Steos -->

      <v-row>
        <v-col cols="12" md="6">
          <specialty-accordion-input
              v-model="entityData.faq"
              @expand="displayFaq = $event"
              :specialty-id="entityData.uuid"
              :lang="selectedLang"
              title="wsu_structure.specialty.faq.title"
              type="faq"

          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayFaq"
              class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.faq.tooltip') }}
          </h5>
        </v-col>

        <!-- Enrollment steps -->
        <v-col cols="12" md="6">
          <specialty-accordion-input
              v-model="entityData.enroll_faq"
              @expand="displayEnrollFaq = $event"
              :specialty-id="entityData.uuid"
              :lang="selectedLang"
              title="wsu_structure.specialty.enroll_faq.title"
              type="enroll_faq"
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 v-if="displayEnrollFaq" class="font-weight-regular wsDARKLIGHT" :class="[{'mt-15' : !SM}]">
            {{ $t('wsu_structure.specialty.enroll_faq.tooltip') }}
          </h5>
        </v-col>

      </v-row>


      <v-sheet height="150"></v-sheet>
    </v-sheet>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";
import courseDescriptionFeatures from "@/components/pages/westudy/admin/course/UI/courseDescriptionFeatures.vue";
import specialtyAccordionInput from "@modules/wsu_structure/components/description/specialtyAccordionInput.vue";
import descriptionSpecialtyEnrollmentCommittee
  from "@modules/wsu_structure/components/description/descriptionSpecialtyEnrollmentCommittee.vue";
import descriptionSpecialtyAlumni from "@modules/wsu_structure/components/description/descriptionSpecialtyAlumni.vue";
import descriptionSpecialtyCareer from "@modules/wsu_structure/components/description/descriptionSpecialtyCareer.vue";
import descriptionSpecialtyProgram from "@modules/wsu_structure/components/description/descriptionSpecialtyProgram.vue";
export default {
  name: "descriptionSpecialty",
  components: {
    courseDescriptionFeatures,
    imageParamPicker,
    descriptionSpecialtyAlumni,
    specialtyAccordionInput,
    descriptionSpecialtyEnrollmentCommittee,
    descriptionSpecialtyCareer,
    descriptionSpecialtyProgram
  },
  props : {
    uuid : {
      type : String
    }
  },
  computed : {
    ...mapState('wsu_structure' , [
        'selectedLang' ,
      'selectedEntity' ,
    ]),

    entityUuid() {
      return this.selectedEntity.uuid
    }
  },
  data() {
    return {
      displayPreview : false,
      entityData : {
        guarantor : {}
      },
      displayGeneralInfo : true,
      displayGuarantor : false,
      displayAlumnis: false,
      displayFaq : false,
      displayEnrollFaq : false,
      displayEnrollCommittee : false,
      displayCareer : false
    }
  },
  watch : {
    entityUuid() {
      this.entityData = this.COPY(this.selectedEntity)
    },
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('wsu_structure' , [
        "ADD_SPECIALTY",
        "GET_SPECIALTY_ADMIN"
    ]),

    async saveChanges() {
      this.entityData.lang = this.selectedLang
      let result = await this.ADD_SPECIALTY(this.entityData)
      if (!result) {
        return this.ERROR()
      }
      this.$store.state.wsu_structure.selectedEntity = this.handleData(result)
      this.notify(this.$t('ChangesSaved') , 'success')
    },
    handleData(data) {
      if (!data.guarantor) {
        data.guarantor = {
          name : '',
          img : '',
          short_description : '',
          description : [ { "isHtml": true, "content": "" } ]
        }
      }
      return this.COPY(data)
    },
    async initPage() {
      let result = await this.GET_SPECIALTY_ADMIN({
        uuid : this.uuid,
        lang : this.selectedLang
      })
      if (!result) {
        return this.ERROR()
      }
      this.entityData = this.handleData(result)
    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>