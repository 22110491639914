<template>
  <specialty-accordion-input
      v-model="value"
      @input="$emit('input', $event)"
      @expand="$emit('expand' , $event)"
      :specialty-id="specialtyId"
      :lang="$store.state.wsu_structure.selectedLang"
      title="wsu_structure.specialty.enroll_committee.title"
      type="enroll_committee"
      :blank-element="blankElement"
  >

    <template #item="{item}">
      <img :src="item.img"
             height="52"
             width="52"
             style="border-radius: 50%; object-fit: cover"
             alt=""
             class="mr-4 my-2"
      />
      <div class="py-3">
        <h5 class="wsDARKER shorten-text">{{ item.name }}</h5>
        <h5 class="font-weight-regular shorten-text">{{ item.position }}</h5>
      </div>
    </template>


    <template #editor="{entityData}">
      <h5 class="font-weight-regular wsACCENT mb-2">
        {{ $t('wsu_structure.specialty.enroll_committee.img') }}
      </h5>
      <image-param-picker
          v-model="entityData.img"
          no-border
          class="mb-5"
          disable-portal
      />
      <!-- Name-->
      <ws-text-field
          :label="$t('HumanName')"
          :placeholder="$t('EnterHumanName')"
          v-model="entityData.name"
          class="mb-5"
      />
      <!-- Position-->
      <ws-text-field
          :label="$t('wsu_structure.specialty.enroll_committee.position.label')"
          :placeholder="$t('wsu_structure.specialty.enroll_committee.position.placeholder')"
          v-model="entityData.position"
          class="mb-5"
      />

      <!-- Phone -->
      <h5 class="font-weight-regular wsACCENT mb-2">
        {{ $t('Phone') }}
      </h5>

      <ws-text-field
          :placeholder="$t('EnterPhone')"
          v-for="(_,index) in entityData.phone"  :key="index + 'phone'"
          v-model="entityData.phone[index]"
          class="mb-2"
          clearable
          @input="handleClearArrayItem($event, index , 'phone' , entityData)"
      />
      <ws-button
          @click="entityData.phone.push('')"
          label="AddPhoneNumber"
          class="mt-2"
      />

      <!-- Email -->
      <h5 class="font-weight-regular wsACCENT mb-2 mt-5">
        {{ $t('Email') }}
      </h5>

      <ws-text-field
          :placeholder="$t('EnterEmail')"
          v-for="(_,index) in entityData.email"  :key="index + 'phone'"
          v-model="entityData.email[index]"
          class="mb-2"
          clearable
          @input="handleClearArrayItem($event, index , 'email' , entityData)"
      />
      <ws-button
          @click="entityData.email.push('')"
          label="AddEmail"
          class="mt-2"
      />


    </template>

  </specialty-accordion-input>
</template>

<script>
import specialtyAccordionInput from "@modules/wsu_structure/components/description/specialtyAccordionInput.vue";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";

export default {
  name: "descriptionSpecialtyEnrollmentCommittee",
  components: {imageParamPicker, specialtyAccordionInput},
  props : {
    value : {
      type : Object,
      default() {
        return {}
      }
    },
    specialtyId : {
      type : String,
    }
  },
  data() {
    return {
      blankElement : {
        img : '',
        name : '',
        position : '',
        phone : [],
        email : [],
      }
    }
  },
  methods : {
    handleClearArrayItem(value , index , key , entityData) {
      if (!value) {
        entityData[key].splice(index,1)
      }
    }
  }
}
</script>



<style scoped>

</style>