<template>
  <div>
    <h5 class="font-weight-regular wsACCENT mb-2">
      {{ $t('courses.description.features.title') }}
    </h5>
    <v-sheet v-if="!features.length" height="100" :color="wsLIGHTCARD" class="d-flex align-center justify-center">
      <ws-button @click="addFeature" label="Add"></ws-button>
    </v-sheet>

    <div v-else>
      <div
           style="border : 1px solid var(--wsBACKGROUND)"
           class="wsRoundedLight "
      >

        <div v-for="(item,i) in features" :key="i"
             :style="i < features.length - 1 ? `border-bottom : 1px solid var(--wsBACKGROUND)` : null"
             class="d-flex justify-space-between pa-4"
        >
          <ws-text-field
              v-model="item.text"
              placeholder="EnterText"
              width="100%"
              solo flat
              rows="1"
              area dense
          />
          <v-btn
              @click="features.splice(i,1)"
              :color="wsACCENT"
              icon
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>

      </div>

      <ws-button @click="addFeature" class="mt-3" label="Add"></ws-button>
    </div>

  </div>

</template>

<script>

export default {
  name: "courseDescriptionFeatures",
  props : {
    value : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      features : []
    }
  },
  watch : {
    features : {
      handler() {
        if (!this.EQ(this.value, this.features)) {
          this.$emit('input', [...this.features])
        }
      },
      deep: true
    },
    value() {
      if (!this.EQ(this.value, this.features)) {
        this.features = [...this.value]
      }
    }
  },
  methods : {
    addFeature() {
      this.features.push({
        text : ''
      })
      this.features = [...this.features]
      this.$emit('input', [...this.features])
    }
  },
  mounted() {
    if ( this.value ) {
      this.features = this.value
    }
  }
}
</script>

<style scoped>

</style>
